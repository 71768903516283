import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { BooktechAppService } from '@btlib-core';

import { MiscUtil } from '@btlib-core'

import { ActivatedRouteSnapshot } from '@angular/router';
import { HookType } from '@btlib-core'
import { EventName } from '@btlib-core'

// import { KlaroService } from '@app/plugins/klaro/services/klaro.service';

export const APP_DATAID = {
  "Cart": "Cart",
  "ProductFavorites": "ProductFavorites"
};

export const AppEvent = {
  "CartAdd": "CartAdd",
  "CartAddFormValidate": "CartAddFormValidate",
  
  "CartEmpty": "CartEmpty",
  "CartRemoveItem": "CartRemoveItem",
  "CartUpdated": "CartUpdated",

  "PwUpdate": "PwUpdate",

  "MenuOpen": "MenuOpen",
  // "MenuOpenEula": "MenuOpenEula",
  // "MenuOpenContact": "MenuOpenContact",
  // "MenuOpenAbout": "MenuOpenEula",
  // "MenuOpenEula": "MenuOpenEula",


  "KlaroConsentUpdate": "KlaroConsentUpdate",

}

export const PTYPE = {
  LODGING: "g_lodging_lodging",
  EXPERIENCE: "g_experience"
}


@Injectable({
  providedIn: 'root'
})
export class AppService {

  public bas = inject(BooktechAppService);
  // public ks:KlaroService|undefined;
  // ks:KlaroService|undefined;

  platformId = inject(PLATFORM_ID); 


  cart:any = {

  }
  header:any = {
    title: ""
  }


  name = "AppService"

  constructor() {
    if(this.bas.envtest) console.log("AppService: ", this.bas.settings?.appId);

    // if (isPlatformBrowser(this.platformId)) 

    let fc = this.bas.ds.findCompany(true);
    
    let gtmId = this.bas.envtest ? fc?.gtmIdTest :  fc?.gtmIdProd;
    if (!gtmId && this.bas.envtest) gtmId = "GTM-M543MJMQ";

    // let gtmId = this.bas.envtest ?  "GTM-M543MJMQ" : fc?.gtmIdProd;
    this.bas.ts.init({
      klaro: true,
      cb: {
        enabled: true
      },
      gtm: {
        enabled: !!gtmId,
        id: gtmId
      }
    });
  

    if(this.bas.envtest) console.log(MiscUtil.getLogText(
      "AppService.constructor, cid: " + this.bas.ds.findCid('app.service.constructor') + ", fc: "
    ), );

    this.bas.hs.on(
      HookType.WebApiRequest, 
      (params) => this.onWebApiRequest(params),
      50
    );

    // if (this.bas.envtest) {

    // }
    
    // this.bas.es.ready().then(() => {
      // if(this.bas.envtest) console.log(MiscUtil.getLogText("AppService, ready.cid: " + this.bas.ds.findCid()));

      this.bas.ds.find(APP_DATAID.Cart, { }, false, true).subscribe((cart:any) => {
        if(this.bas.envtest) console.log(MiscUtil.getLogText("AppService.cart.then"), MiscUtil.stringify(cart));
  
        this.cart = cart;
        this.updateCart(false);
  
      });
    // });




    this.bas.es.on(AppEvent.CartAdd, (ev:any) => {
      if(this.bas.envtest) console.log("AppService.CartAdd, ev: ", ev);
      this.cart.items = this.cart.items || [];
      this.cart.items.push(ev.pw);
      

      this.updateCart(true);

      // this.bas.ts.track(EventName.AddToCart.name)

      this.bas.ts.track(EventName.AddToCart.name, {
        content_name: ev.pw.product,
        content_id: ev.pw.productId,
        currency: "NOK",
        value: this.cart.sum 
      });


    //   JUAnalytics.save(request, new AnalyticsItem(
    //     company, 
    //     AnalyticsItem.Type.Product, 
    //     AnalyticsItem.Action.AddToCart, 
    //     "product-" + pw.getProduct().getId(), 
    //     pw.getProduct().getName(), 
    //     null), 
    //     "setCart"
    //  );
    
    });

    this.bas.es.on(AppEvent.CartEmpty, (ev:any) => {
      this.cart = { };

      this.updateCart(true);
    });
    this.bas.es.on(AppEvent.CartRemoveItem, (ev:any) => {
      this.cart.items = this.cart.items.filter((ci:any) => ci.id != ev.item.id);

      this.updateCart(true);
    });


   }


  checkActivatedRoute(route:ActivatedRouteSnapshot):boolean {
    let fc = this.bas.ds.findCompany();
    if(this.bas.envtest) console.log("AppService.checkActivatedRoute, enabled: " + fc?.upCbCbsiteEnabled + ", fc: ", this.bas.ssr ? fc?.username : fc);
    
 

    if (fc == undefined || fc?.upCbCbsiteEnabled === undefined) {
      return true;
    }

    if (fc.upCbCbsiteEnabled) {
      return true;
    }

    return true; //TODO

    console.log("AppService.checkActivatedRoute, CB SITE IS NOT ENABLED, fc: ", fc);
    

    this.bas.ui.router.navigate(['/']);

    return false;
  }



  async onWebApiRequest(params:any) {
    
    return new Promise<boolean>((res, rej) => {
      if (this.bas.envtest) console.log("AppService.onWebApiRequest: ", params);

     
      let qp = params.queryParams || { };
      qp = MiscUtil.clone(qp);

      let page = decodeURIComponent( qp["cbapi-page"] );
      let action = qp["cbapi-action"];
      let toolsAction = qp["cbapi-tools-action"];
      let error = qp["cbapi-error"];

      let returnUri = qp["cbapi-return-uri"];

      delete qp["cbapi-page"];
      delete qp["cbapi-action"];
      delete qp["cbapi-tools-action"];
      delete qp["cbapi-error"];
      delete qp["cbapi-return-uri"];

      let basePath = this.bas.ui.getRouterPrefix();

      let target = "";
      let queryParams:any = { };
      let queryParamsAll:any = { };

      for (let pn in qp) {
        // if (!pn.startsWith("cbapi-option-")) continue;
        let cbapiOpt = pn.startsWith("cbapi-option-");
        let name = cbapiOpt ? pn.substring("cbapi-option-".length) : pn;
        if (cbapiOpt) queryParams[name] = qp[pn];

        // if (new Set(["cbapi-page", "cbapi-action", "cbapi-tools-action"]).has(pn)) continue;
        queryParamsAll[name] = qp[pn];
      }


      


      if (page) {

        target = "/" + page;

        
        if (page == "index") {
          target = basePath;
        } else if (page == "order/payment") {

          target = basePath +  "/payment"; // + target; // /lib/order/payment/?id=dfdf
          
        } else if (page == "order/product") {

          let pType = queryParams["ptype"] || "produkt";
          let pInfo = queryParams["pinfo"] || "produkt";
          let pName = queryParams["pname"] || "produkt";
          target = basePath + `/p/${pType}/${pInfo}/${pName}-` + queryParams["id"]; 

          delete queryParams["ptype"];
          delete queryParams["pinfo"];
          delete queryParams["pname"];
          delete queryParams["id"];

        } else if (page == "confirmUser") {

          if (error ) {
            target = basePath;

            queryParams = {
          
            }

            this.bas.ui.error(this.bas.ui.actrans("app.lib.pages.user.confirm.invalidConfirmKey"), 10)
          } else {
            target = basePath +  "/lib/user/confirm";

            queryParams = {
              action: toolsAction
            }

            if (returnUri) {
              queryParams["return-uri"] = returnUri
            }
          }

       
        }


        
      }

      if (action) {
        if (action == "showNewPasswordDialog") {
          // TODO:test
          target = "/lib/user/password";


          // this.apps.bas.ws.login({
          //   checkLogin: true,
          //   uid: qp["cbapi-uid"] || "",
          //   accessKey: qp["cbapi-ak"] || "",
          //   callback: (al:any) => {
          //     console.log("al: ", al);
          //     if (!al.success) {
          //       target = "/";
          //     }
          //     this.apps.bas.ui.router.navigate( [ target ] );

          //   }
          // }).toPromise().then(() => { });

          return;
        }
        if (action == "mypage") {

          target = basePath + "/user";

        }

        // https://192.168.1.60:8204/booking/runguviaferrata/no/lib/api?apisrc=cbclassic&cbapi-action=stripeCheckoutCallback&stid=4698&result=success
        if (action == "stripeCheckoutCallback") {

          target = basePath + "/payment-complete";

          queryParams = queryParamsAll;
        }
      }


      if (toolsAction == "confirmUserLp")  {

        target = basePath + "/user";

        this.bas.ui.success(this.bas.ui.actrans("app.lib.api.info.confirmUser.confirmed"))
      }


      console.log("cbsite.target: " + target + ", queryParams: ", queryParams);
      
      if (target) {
          this.bas.ui.router.navigate(
            [ target ], 
            { queryParams: queryParams }
          );
          res(false);
      }

      res(false);
      

      // res(true);
    });
  }


  updateCart(save:boolean) {
    let c = this.cart;
    c.items = c.items || [];
    // if (this.bas.envtest) console.log("updateCart, c.items: ", c.items);
    let sum = 0;
    let raSum = 0;
    for (let pw of c.items) {
      sum += pw.calcAmount || pw.amountDouble || pw.totalAmountDouble;
      raSum += pw.retailAmountDouble || 0;

      for (let pi of pw.partItems || []) {
        // sum += pi.amountDouble;
        raSum += pi.retailAmountDouble || 0;
      }
  
      for (let acc of pw.accessories || []) {
        // sum += acc.amountDouble;
        if (!acc.quantity) continue; 
        raSum += acc.retailAmountDouble || 0;
      }

      // for (let acc of pw.accessoriesMandatory || []) {
      //   // sum += acc.amountDouble;
      //   raSum += acc.retailAmountDouble || 0;
      // }
      // for (let acc of pw.accessoriesOptional || []) {
      //   // sum += acc.amountDouble;
      //   raSum += acc.retailAmountDouble || 0;
      // }
      for (let gi of pw.groupItems || []) {
        // sum += gi.amountDouble;
        raSum += gi.retailAmountDouble || 0;
      }

    }
    // if (this.bas.envtest) console.log("updateCart, c.items: ", c.items);
    c.count = c.items.length;
    c.sum = sum;
    c.raSum = raSum;
    c.items2 = c.items;
    if (this.bas.envtest) console.log("updateCart, c: ", c);
    // if(this.bas.envtest) console.log("AppService.updateCart: ", JSON.stringify(c));
     
    if (save) this.bas.ds.save(APP_DATAID.Cart, c, false, true);
    // if(this.bas.envtest) console.log("AppService.updateCart: ", c );
    this.bas.es.trigger(AppEvent.CartUpdated, { cart: c });
  }

  // /_?aType=customAction&action=search&appId=cbsite&startDate=&endDate&jil=min&productTypeAsString=Experience

  search(params:any = { }):Promise<any> {
    params = MiscUtil.extend({
      aType: "customAction", 
      action: "search", 
      jil: "min",
      jil2: "medium",
      // productTypeAsString: ""
    }, params);
    // MiscUtil.extend(params, extraParams || { });

    return this.bas.ws.json(params).then((json) => {
      if (this.bas.envtest && this.bas.nossr) console.log("search, json: ", json);
      if (json.success) {
        for (let pw of json.ps.results || []) {

          let p = pw.productObj;
          this.bas.acs.updateProductObj(p);

          for (let ar of pw.allResults || []) {

            this.bas.acs.updateProductObj(ar.productObj);
          }
        }
        // return p;
      }
      return json;

    });
  }

  productGet(id:number|string, extraParams:any = { }):Promise<any> {
    let params = {
      aType: "customAction", 
      action: "getProduct", 
      id: id
    }
    MiscUtil.extend(params, extraParams || { });

    return this.bas.ws.json(params).then((json) => {
      // if (this.bas.envtest) console.log("getProduct, json: ", json);
      if (json.success) {
        let p = json.product;
        this.bas.acs.updateProductObj(p);
        // return p;
      }
      return json;

    });
  }
  
  async updatePw(pwPrev:any, args?:any) {

    
    let res = await this.bas.os.updatePw(pwPrev);
    
    let pw = res.pw;

    this.bas.es.trigger(AppEvent.PwUpdate, { id: pw.id, pw: pw, success: res.success, res: res }); // , res: res
 
    // Feilmelding
    return pw;
  }

}
